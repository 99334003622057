<template>
	<b-card no-body class="mb-1">
		<b-card-header v-b-toggle="this.collapseId" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="align-baseline p-1 pl-2" role="tab">
			<font-awesome-icon icon="caret-down" fixed-width />
		Tub and Tray <span class="float-right mr-2"><small>{{ this.tubTrayOptions[this.value] }}</small></span>
		</b-card-header>
		<b-collapse :id="collapseId" visible role="tabpanel">
			<b-card-body>
				<b-form-radio-group v-model="selected" :options="this.options" stacked />
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
export default {
  props: ['value', 'width'],
	data() {
		return {
			tubTrayOptions: this.$store.getters.getOption('upperCabinet', 'tubTrayOptions'),
		  selected: this.value,
		  collapseId: 'tubTray'
		}
	},
	computed: {
		options() {
			let options = [
				{text: 'No Tub & Tray', value: 'none'},
				{text: 'Single Tub & Tray Storage', value: 'single'},
				{text: 'Double Tub & Tray Storage', value: 'double'},
				{text: 'Triple Tub & Tray Storage', value: 'triple'}
		  ]
		  switch(this.width) {
		  	case 500:
		  		return options.slice(0,2)
		  		break
		  	case 1000:
		  		return options.slice(0,3)
		  		break
		  	default:
		  		return options
		  }
		}
	},
	watch: {
		selected() {
			this.$emit('input', this.selected);
		}
	}
}
</script>

<style scoped lang="scss">
</style>
