import Vue from "vue"
import Vuex from "vuex"
import pathify, { make } from 'vuex-pathify'

import api from "./api"


const OPTIONS = {
	island: {
		countertopOptions: {
			'standard': 'Solid Surface Standard White',
			'white_sink': 'Solid Surface with Bright White Sink',
			'ss_sink': 'Stainless Steel 12" Round Sink'
		}
	},
	lowerCabinet: {
		specificationText: {
			kickbase: {
				'1': 'Drawer <small>(110)</small> and Door',
				'2': '3 Drawer <small>(110/220/330)</small>',
				'3': '4 Drawer <small>(110/110/220/220)</small>',
				'4': '4 Drawer <small>(110/110/110/330)</small>',
				'5': 'Sink Unit'
			},
			hung: {
				'1': 'Drawer <small>(110)</small> and Door',
				'2': '3 Drawer <small>(110/220/220)</small>',
				'3': '3 Drawer <small>(110/110/330)</small>',
				'4': '4 Drawer <small>(110/110/110/220)</small>',
				'5': 'Sink Unit'
			},
			tall: {
				'1': 'Drawer <small>(110)</small> and Door',
				'2': '2 Drawer <small>(330/440)</small>',
				'3': '3 Drawer <small>(110/220/440)</small>',
				'4': '4 Drawer <small>(110/110/110/440)</small>'
			}
		},
		drawerOptions: {
			'standard': 'Standard Drawer',
			'removable': 'Removable Drawertop Insert',
			'keyboard': 'Keyboard Tray',
			'perfect_pull': 'Perfect Pull',
			'tray': 'Medical Tray Drawer Insert',
			'rollout': 'Rollout Equipment Pocket',
			'open': 'Open/No Shelf',
			'shelf': 'Adjustable Shelf',
			'cpu': 'CPU Storage'
		},
		countertopOptions: {
			'standard': 'Solid Surface Standard White',
			'round_sink': 'Solid Surface with Round Sink',
			'square_sink': 'Solid Surface with Square Sink'
		}
	},
	upperCabinet: {
		dispensingOptions: {
			'none': 'No Dispensing',
			'soap_paper': 'Soap & Sanitary with Paper',
			'soap_cup': 'Soap & Sanitary with Cup',
			'wet': 'Wet Dispensing',
			'dry': 'Dry Dispensing'
		},
		tubTrayOptions: {
			'none': 'No Tub & Tray',
			'single': 'Single Tub & Tray Storage',
			'double': 'Double Tub & Tray Storage',
			'triple': 'Triple Tub & Tray Storage'
		}
	}
}
Object.freeze(OPTIONS)

const DETAILS = {
  accentColours: [
    {
      id: 1,
      src: require('../../../app/assets/images/accent_colours/granite-crystal.png'),
      alt: 'Granite Crystal'
    },
    {
      id: 2,
      src: require('../../../app/assets/images/accent_colours/indium-grey.png'),
      alt: 'Indium Grey'
    },
    {
      id: 3,
      src: require('../../../app/assets/images/accent_colours/cashmere.png'),
      alt: 'Cashmere'
    },
    {
      id: 4,
      src: require('../../../app/assets/images/accent_colours/cheyenne-red.png'),
      alt: 'Cheyenne Red'
    },
    {
      id: 5,
      src: require('../../../app/assets/images/accent_colours/spicy-orange.png'),
      alt: 'Spicy Orange'
    },
    {
      id: 6,
      src: require('../../../app/assets/images/accent_colours/redl-red.png'),
      alt: 'Redl Red'
    },
    {
      id: 7,
      src: require('../../../app/assets/images/accent_colours/dark-cherry.png'),
      alt: 'Dark Cherry'
    },
    {
      id: 8,
      src: require('../../../app/assets/images/accent_colours/indigo-finish.png'),
      alt: 'Indigo Finish'
    },
    {
      id: 9,
      src: require('../../../app/assets/images/accent_colours/lightening-blue.png'),
      alt: 'Lightening Blue'
    },
    {
      id: 10,
      src: require('../../../app/assets/images/accent_colours/lime-green.png'),
      alt: 'Lime Green'
    },
    {
      id: 11,
      src: require('../../../app/assets/images/accent_colours/polar-blue.png'),
      alt: 'Polar Blue'
    },
    {
      id: 12,
      src: require('../../../app/assets/images/accent_colours/thunderbird-blue.png'),
      alt: 'Thunderbird Blue'
    },
    {
      id: 99,
      src: require('../../../app/assets/images/accent_colours/custom.png'),
      alt: 'Custom'
    }
  ],
  doorColours: [
    {
      id: 1,
      src: require('../../../app/assets/images/door_colours/hacienda-white.png'),
      finish: 'gloss',
      alt: 'Hacienda White'
    }, {
      id: 2,
      src: require('../../../app/assets/images/door_colours/hacienda-black.png'),
      finish: 'gloss',
      alt: 'Hacienda Black'
    }, {
      id: 3,
      src: require('../../../app/assets/images/door_colours/olive-woodgrain.png'),
      finish: 'gloss',
      alt: 'Olive Woodgrain'
    }, {
      id: 4,
      src: require('../../../app/assets/images/door_colours/soho-umber.png'),
      finish: 'gloss',
      alt: 'Soho Umber'
    }, {
      id: 5,
      src: require('../../../app/assets/images/door_colours/soho-stone-grey.png'),
      finish: 'gloss',
      alt: 'Soho Stone Grey'
    }, {
      id: 6,
      src: require('../../../app/assets/images/door_colours/soho-sahara.png'),
      finish: 'gloss',
      alt: 'Soho Sahara'
    }, {
      id: 7,
      src: require('../../../app/assets/images/door_colours/soho-quartz.png'),
      finish: 'gloss',
      alt: 'Soho Quartz'
    }, {
      id: 8,
      src: require('../../../app/assets/images/door_colours/soho-pearl-grey.png'),
      finish: 'gloss',
      alt: 'Soho Pearl Grey'
    }, {
      id: 9,
      src: require('../../../app/assets/images/door_colours/soho-bright-white.png'),
      finish: 'gloss',
      alt: 'Soho Bright White'
    }, {
      id: 10,
      src: require('../../../app/assets/images/door_colours/mesa-lava.png'),
      finish: 'matte',
      alt: 'Mesa Lava'
    }, {
      id: 11,
      src: require('../../../app/assets/images/door_colours/mesa-stone-grey.png'),
      finish: 'matte',
      alt: 'Mesa Stone Grey'
    }, {
      id: 12,
      src: require('../../../app/assets/images/door_colours/mesa-taupe-grey.png'),
      finish: 'matte',
      alt: 'Mesa Taupe Grey'
    }, {
      id: 13,
      src: require('../../../app/assets/images/door_colours/mesa-premium-white.png'),
      finish: 'matte',
      alt: 'Mesa Premium White'
    }
  ],
  doorHandles: [
    {
      id: 1,
      sku: 'M1158',
      src: require('../../../app/assets/images/door_handles/square_satin_nickel.png'),
      alt: 'Square',
      finish: 'nickel'
    },
    {
      id: 2,
      sku: 'M1173',
      src: require('../../../app/assets/images/door_handles/tapered_brushed_satin_nickel.png'),
      alt: 'Tapered',
      finish: 'nickel'
    },
    {
      id: 3,
      sku: 'TK3012BSN',
      src: require('../../../app/assets/images/door_handles/telfair_brushed_satin_nickel.png'),
      alt: 'Telfair',
      finish: 'nickel'
    },
    {
      id: 4,
      sku: 'TK522BSN',
      src: require('../../../app/assets/images/door_handles/euro_brushed_satin_nickel.png'),
      alt: 'Euro',
      finish: 'nickel'
    },
    {
      id: 5,
      sku: 'M1160',
      src: require('../../../app/assets/images/door_handles/square_polished_chrome.png'),
      alt: 'Square',
      finish: 'chrome'
    },
    {
      id: 6,
      sku: 'M1175',
      src: require('../../../app/assets/images/door_handles/tapered_polished_chrome.png'),
      alt: 'Tapered',
      finish: 'chrome'
    },
    {
      id: 7,
      sku: 'TK3012PC',
      src: require('../../../app/assets/images/door_handles/telfair_polished_chrome.png'),
      alt: 'Telfair',
      finish: 'chrome'
    },
    {
      id: 8,
      sku: 'TK522PC',
      src: require('../../../app/assets/images/door_handles/euro_polished_chrome.png'),
      alt: 'Euro',
      finish: 'chrome'
    },
    {
      id: 9,
      sku: 'BP57680140',
      src: require('../../../app/assets/images/door_handles/metal_edge.png'),
      alt: 'Metal Edge',
      finish: 'chrome'
    },
    {
      id: 10,
      sku: 'M1159',
      src: require('../../../app/assets/images/door_handles/square_flat_black.png'),
      alt: 'Square',
      finish: 'black'
    },
    {
      id: 11,
      sku: 'M1174',
      src: require('../../../app/assets/images/door_handles/tapered_flat_black.png'),
      alt: 'Tapered',
      finish: 'black'
    },
    {
      id: 12,
      sku: 'TK3012BLK',
      src: require('../../../app/assets/images/door_handles/telfair_flat_black.png'),
      alt: 'Telfair',
      finish: 'black'
    },
    {
      id: 13,
      sku: 'TK3012BLK',
      src: require('../../../app/assets/images/door_handles/telfair_flat_black.png'),
      alt: 'Telfair',
      finish: 'black'
    },
    {
      id: 14,
      sku: 'TK522BLK',
      src: require('../../../app/assets/images/door_handles/euro_flat_black.png'),
      alt: 'Euro',
      finish: 'black'
    },
    {
      id: 15,
      sku: 'M2157',
      src: require('../../../app/assets/images/door_handles/square_honey_bronze.png'),
      alt: 'Square',
      finish: 'bronze'
    },
    {
      id: 16,
      sku: 'M1898',
      src: require('../../../app/assets/images/door_handles/tapered_honey_bronze.png'),
      alt: 'Tapered',
      finish: 'bronze'
    },
    {
      id: 17,
      sku: 'TK3012HB',
      src: require('../../../app/assets/images/door_handles/telfair_honey_bronze.png'),
      alt: 'Telfair',
      finish: 'bronze'
    }
  ],
  faucets: [
    {
      id: 1,
      alt: 'BO-BN',
      src: require('../../../app/assets/images/faucets/bo-bn.png'),
      finish: 'nickel'
    },
    {
      id: 2,
      alt: 'DC-BN',
      src: require('../../../app/assets/images/faucets/dc-bn.png'),
      finish: 'nickel'
    },
    {
      id: 3,
      alt: 'BO-PC',
      src: require('../../../app/assets/images/faucets/bo-pc.png'),
      finish: 'chrome'
    },
    {
      id: 4,
      alt: 'DC-PC',
      src: require('../../../app/assets/images/faucets/dc-pc.png'),
      finish: 'chrome'
    }
  ],
  finishes: [
  ]
}
Object.freeze(DETAILS)

const state = {
  projects: [],
  cabinets: {},
  breadcrumbItems: [{
      text: 'Projects',
      to:   { path: '/projects' }
  }],
  newCabinets: {
    type: 'cabinet',
    n: null,
    location: null,
    accentColour: {},
    doorColour: {},
    doorHandle: {},
    faucet: {},
    upperAccent: 'none',
    lowerAccent: 'none',
    finishedEnd: false,
    lowerCabinetStyle: 'cloud',
    lowerCabinets: [],
    upperCabinets: []
  },
  newIsland: {
    type: 'island',
    n: null,
    location: null,
    size: 8,
    accentColour: {},
    doorColour: {},
    doorHandle: {},
    faucet: {},
    upperAccent: 'none',
    lowerAccent: 'none',
  },
  newProject: {
    projectName: "New Project",
    dealerName: "",
    location: "",
    salesRep: "",
    customerName: "",
    comments: "",
    treatmentCentres: [{
      name: 'Treatment Centre 1',
      left: {
        type: 'none',
        id: 0
      },
      rear: {
        type: 'none',
        id: 0
      },
      right: {
        type: 'none',
        id: 0
      }
    }]
  },
  details: DETAILS,
  options: OPTIONS,
  user: JSON.parse(localStorage.getItem("user"))
}

const getters = {
  getOption: state => (domain, name) => state.options[domain][name],
  getOptionById: state => (domain, name, id) => state.options[domain][name].find(option => option.id === id),
  getDetails: state => (category) => {
    return state.details[category]
  },
  getDetailById: state => (category, id) => {
    return state.details[category].find(option => option.id === id)
  },
  getGroupedDetails: state => (category, groupBy) => {
    return state.details[category].reduce(function(storage, item) {
      let group = item[groupBy]
      storage[group] = storage[group] || []
      storage[group].push(item)
      return storage
    }, {})
  },
  getNewCabinets: state => (n,loc) => {
    let newCabinets = state.newCabinets
    newCabinets.n = n
    newCabinets.location = loc
    newCabinets.accentColour = { id: state.details.accentColours[0].id }
    newCabinets.doorColour = { id: state.details.doorColours[0].id }
    newCabinets.doorHandle = { id: state.details.doorHandles[0].id }
    newCabinets.faucet = { id: state.details.faucets[0].id }
    return newCabinets
  },
  getNewIsland: state => (n,loc) => {
    let newIsland = state.newIsland
    newIsland.n = n
    newIsland.location = loc
    newIsland.accentColour = { id: state.details.accentColours[0].id }
    newIsland.doorColour = { id: state.details.doorColours[0].id }
    newIsland.doorHandle = { id: state.details.doorHandles[0].id }
    newIsland.faucet = { id: state.details.faucets[0].id }
    newIsland.upperAccent = 'none'
    newIsland.surfaceTreatment = 'standard'
    return newIsland
  },
  projectCount: state => state.projects.length,
  getProjectById: state => id => state.projects.find(project => project.id === id),
  getCabinetById: state => id => state.cabinets[id],
  getIslandById: state => id => state.islands.find(island => island.id === id),
  isAuthenticated: state => !!state.user
}

const actions = {
  getProjects({ commit }) {
    if (this.getters.isAuthenticated) {
      api.getProjects().then(response => {
        var projects = response.data.map(function(i) {
          return Object.assign({id: i.id}, JSON.parse(i.data))
        })
        commit("setProjects", projects)
      })
    }
  },
  getCabinet({ commit }, { projectId, cabinetId }) {
    if (this.getters.isAuthenticated) {
      api.getCabinet(projectId, cabinetId).then(response => {
        var cabinet = Object.assign(
          {
            id: response.data.id,
            type: response.data.cabinet_type,
            projectId: response.data.project_id
          },
            JSON.parse(response.data.data)
          )
        commit("addCabinets", cabinet)
        return cabinet
      })
    }
  },
  getCabinets({ commit }, projectId) {
    if (this.getters.isAuthenticated) {
      api.getCabinets(projectId).then(response => {
        for (var i in response.data) {
          commit("addCabinets", Object.assign(
          {
            id: response.data[i].id,
            type: response.data[i].cabinet_type,
            projectId: response.data[i].project_id
          },
            JSON.parse(response.data[i].data)
          ))
        }
      })
    }
  },
  createProject({ commit }, project) {
    commit("addProject", project)
  },
  createIsland({ commit }, island) {
    commit("addIsland", island)
  },
  createCabinets({ commit }, projectId) {
    commit("addCabinets", cabinets)
  },
  updateBreadcrumbItems({ commit }, items) {
    commit("setBreadcrumbItems", items)
  },
  signIn({ commit }, { login, password }) {
    // The Promise used for router redirect in Signin.vue
    return new Promise((resolve, reject) => {
      api
        .createSession(login, password)
        .then(response => {
          commit("setUser", response.data);
          this.dispatch("getProjects");
          resolve();
        })
        .catch(error => {
          // if the request fails, clear user
          commit("clearUser");
          reject(error.response.data.error);
        });
    });
  },
  signOut({ commit }) {
    // The Promise used for router redirect in Header.vue
    return new Promise(resolve => {
      api.deleteSession().then(() => {
        commit("clearUser");
        resolve();
      });
    });
  }
}

const mutations = {
  setProjects(state, projects) {
    state.projects = projects
  },
  setCabinets(state, cabinets) {
    state.cabinets = cabinets
  },
  addProject(state, project){
    state.projects.push(project)
  },
  addIsland(state, island) {
    state.islands.push(island)
    let p = this.getters.getProjectById(island.projectId)
    p.treatmentCentres[island.n][island.location].type = 'island'
    p.treatmentCentres[island.n][island.location].id = island.id
  },
  addCabinets(state, cabinets) {
    console.log("Adding cabinets to state: %o", cabinets)
    state.cabinets[cabinets.id] = cabinets
    let p = this.getters.getProjectById(cabinets.projectId)
    p.treatmentCentres[cabinets.n][cabinets.location].type = cabinets.type
    p.treatmentCentres[cabinets.n][cabinets.location].id = cabinets.id
  },
  setBreadcrumbItems(state, items) {
    state.breadcrumbItems = items
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  clearUser(state) {
    state.user = null;
    localStorage.removeItem("user");
    state.projects = [];
  }
}

// store
Vue.use(Vuex)
export default new Vuex.Store({

  plugins: [
    pathify.plugin
  ],

  state,
  getters,
  actions,
  mutations,
})
