import axios from "axios";

const API_URL = process.env.API_URL || "https://redldentl.com/api";

const IMGPATH = '../../../../app/assets/images/'

// `baseURL` is prepended to URLs passed to axios
axios.defaults.baseURL = API_URL;

// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// `xsrfCookieName` is the name of the cookie
// to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axios.defaults.xsrfCookieName = "CSRF-TOKEN";

// `xsrfHeaderName` is the name of the http header
// that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

// `withCredentials` indicates whether or not cross-site
// Access-Control requests should be made using credentials.
axios.defaults.withCredentials = true;

export default {
  getProjects() {
    return axios.get("/projects");
  },
  createProject(aProject) {
    return axios.post("/projects", {project: {data: JSON.stringify(aProject)}});
  },
  updateProject(aProject) {
  	return axios.patch("/projects/" + aProject.id, {project: {data: JSON.stringify(aProject)}});
  },
  deleteProject(projectId) {
  	return axios.delete("/projects/" + projectId)
  },
  getCabinet(projectId, cabinetId) {
  	 return axios.get("/projects/" + projectId + "/cabinets/" + cabinetId);
  },
  getCabinets(projectId) {
    return axios.get("/projects/" + projectId + "/cabinets");
  },
  createCabinet(projectId, cabinetType, aCabinet) {
    return axios.post(`/projects/${projectId}/cabinets`, {cabinet: {cabinet_type: cabinetType, data: JSON.stringify(aCabinet)}});
  },
  updateCabinet(aCabinet) {
  	return axios.patch("/projects/" + aCabinet.projectId + "/cabinets/" + aCabinet.id, {cabinet: {cabinet_type: aCabinet.type, data: JSON.stringify(aCabinet)}});
  },
  deleteCabinet(projectId, cabinetId) {
  	return axios.delete("/projects/" + projectId + "/cabinets/" + cabinetId)
  },
  createSession(login, password) {
    return axios.post("/session", { login, password });
  },
  deleteSession() {
    return axios.delete("/session", {});
  }
};

// Intercept all 401 Unauthorized responses before
// they are handled by `then` or `catch`

import store from "./store"
import router from "./router"

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (!error.response) {
      // network error
      alert(`Unable to access API: ${error.message}`);
    } else if (error.response.status == 401) {
      console.error("Unauthorized! Signing out...");
      store.dispatch("signOut").then(() => {
        router.replace("sign-in");
      });
    }
    return Promise.reject(error);
  }
);
