<% helpers = ActionController::Base.helpers %>
<template>
	<b-card no-body class="mb-1">
		<b-card-header header-tag="header" header-bg-variant="primary" header-text-variant="white" class="p-1 pl-2" role="tab" v-b-toggle.island_size>
			<font-awesome-icon icon="caret-down" fixed-width />Island Size <span class="float-right mr-2"><small>{{ this.value }} Feet</small></span>
		</b-card-header>
		<b-collapse id="island_size" visible role="tabpanel">
			<b-card-body class="p-2">
				<b-form-group class="m-2">
					<b-form-radio-group
						id="Island_Size"
						buttons
						button-variant="outline-primary"
						size="md"
						v-model="selected"
						:options="options"
						name="radioBtnOutline"
					/>
				</b-form-group>
				<b-img center v-bind:src="islandSrc" width="450" height="660" />
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
export default {
  props: ['value'],
	data() {
		return {
		  selected: this.value,
			options: [
				{text: '8 Feet', value: 8, src: require('../../../../app/assets/images/island_sizes/8_foot_island@2x.png')},
				{text: '9 Feet', value: 9, src: require('../../../../app/assets/images/island_sizes/9_foot_island@2x.png')},
				{text: '10 Feet', value: 10, src: require('../../../../app/assets/images/island_sizes/10_foot_island@2x.png')}
			]
		}
	},
	watch: {
		selected() {
			this.$emit('input', this.selected);
		}
	},
	computed: {
		islandSrc() {
			return this.options.find(x => x.value === this.selected).src;
		}
	}
}
</script>
