<template>
	<b-list-group-item>
		<h5 class="text-capitalize">{{ value.location }} {{ value.type }}</h5>
		<b-card-text>
			<div class="mt-1"><strong>Cabinet Style: </strong>{{ value.lowerCabinetStyle }}</div>
			Door Colour: {{ doorColour.alt }}<br>
			Door Handle: {{ doorHandle.finish }} {{ doorHandle.alt }}<br>
			Faucet: {{ faucet.alt }}
			<div v-for="(uc, index) in value.upperCabinets" class="mt-2">
				<strong>Upper Cabinet #{{ index+1 }}</strong> <b-badge>{{ uc.width }} mm</b-badge><br>
				Dispensing Options: {{ uc.dispensingOptions }}<br>
				Tub/Tray Options: {{ uc.tubTrayOptions }}
			</div>
			<div class="mt-1">
				Upper Accent: {{ value.upperAccent }}
				<div v-if="value.upperAccent !== 'none'">
					Accent Colour:
					<span v-if="value.accentColour.id===99">{{ value.accentColour.custom }}</span>
					<span v-else>{{ accentColour.alt }}</span>
				</div>
			</div>
			<div v-for="(lc, index) in value.lowerCabinets" class="mt-2">
				<strong>Lower Cabinet #{{ index+1 }}</strong> <b-badge>{{ lc.width }} mm</b-badge><br>
				{{ cabinetSpecificationText[lc.type][lc.specifications].replace(/<(?:.|\n)*?>/gm, '') }}
				<div v-for="n in drawerCount(lc.type, lc.specifications)" class="pl-2">
					<strong><span v-if="lc.specifications==='1'&&n===2">Door <small>(hinge {{ lc.hinge }})</small></span><span v-else>Drawer #{{n}}</span></strong> {{ drawerOptions[lc.drawerOptions[n].option] }}
				</div>
			</div>
			<div class="mt-1">
				Lower Accent:  {{ value.lowerAccent }}
				<div v-if="value.lowerAccent !== 'none'">
					Accent Colour:
					<span v-if="value.accentColour.id===99">{{ value.accentColour.custom }}</span>
					<span v-else>{{ accentColour.alt }}</span>
				</div>
			</div>
		</b-card-text>
	</b-list-group-item>
</template>

<script>
export default {
	props: ['value'],
	data() {
		return {
			cabinetSpecificationText: this.$store.getters.getOption('lowerCabinet','specificationText'),
			drawerOptions: this.$store.getters.getOption('lowerCabinet', 'drawerOptions')
		}
	},
	computed: {
		accentColour() {
			return this.$store.getters.getDetailById('accentColours', this.value.accentColour.id)
		},
		doorHandle() {
			return this.$store.getters.getDetailById('doorHandles', this.value.doorHandle.id)
		},
		doorColour() {
			return this.$store.getters.getDetailById('doorColours', this.value.doorColour.id)
		},
		faucet() {
			return this.$store.getters.getDetailById('faucets', this.value.faucet.id)
		}
	},
	methods: {
		option(category, id) {
			this.$store.getters.getDetailById(category, id)
		},
		drawerCount(type, specification) {
			if (specification === '1') {
				return 2
			} else if (specification === '5') {
				return 0
			} else {
				return parseInt(this.cabinetSpecificationText[type][specification][0])
			}
		}
	},
	mounted: function() {
		let accentColourDescription = ''
		if (this.value.accentColour.id===99) {
			accentColourDescription = 'Custom: (' + this.value.accentColour.custom + ')'
		} else {
			accentColourDescription = this.accentColour.alt
		}
		let pdfData = {
			accentColour: accentColourDescription,
			doorColour: this.doorColour.alt,
			doorHandle: this.doorHandle.finish + ' ' + this.doorHandle.alt,
			faucet: this.faucet.alt
		}
		this.$emit('pdf', pdfData)
	}
}
</script>

<style scoped lang="scss">
	@import '../assets/main.scss';
	.list-group-item {
		font-size: 80%;
	}
</style>
