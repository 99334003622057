<template>
	<b-container>
		<div v-if="content">
			<b-row>
				<b-col>
					<b-button @click="update" variant="success" class="float-right">
						Save Changes
					</b-button>
					<h2 class="mb-4">
						Edit {{ this.locationNames[this.content.location] }}
						<small class="text-muted ml-2">for {{ this.treatmentCentreName }}</small>
					</h2>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="4">
					<div role="tablist">
						<DoorColour v-model="content.doorColour" />
						<AccentColour v-model="content.accentColour" />
						<DoorHandle v-model="content.doorHandle" />
						<Faucet v-model="content.faucet" />
					</div>
				</b-col>
				<b-col cols="8">
					<div role="tablist">
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" header-bg-variant="primary" header-text-variant="white" class="p-1 pl-2" role="tab" v-b-toggle.collapse_upca>
								<h4 class="my-0">
									<font-awesome-icon icon="caret-down" fixed-width />Upper Cabinets
								</h4>
							</b-card-header>
							<b-collapse id="collapse_upca" visible role="tabpanel">
								<b-card-body>
									<b-row>
										<b-col cols="5">
											<b-form-radio-group id="upper_cabinets" v-model="content.upperAccent" stacked>
												<b-form-radio value="none">No Accent Colour</b-form-radio>
												<b-form-radio value="poly">Poly Accent</b-form-radio>
												<b-form-radio value="logo">Poly Accent with Text Logo</b-form-radio>
											</b-form-radio-group>
										</b-col>
										<b-col cols="7">
											<b-row no-gutters class="justify-content-md-center">
												<b-col v-for="n in content.upperCabinets.length"  :key="`upper-cabinet-image-${n}`">
													<div v-if="(content.upperCabinets[n-1])" :style="cabinetAccentStyle">
														<b-link href="#" @click="upperTabIndex=n-1">
															<div v-if="(content.upperAccent === 'none')" :style="cabinetNoAccentStyle">
																<b-img v-bind="upperCabinetProps(n)" fluid />
															</div>
															<div v-else :style="cabinetAccentStyle">
																<b-img v-bind="upperCabinetProps(n)" fluid />
															</div>
														</b-link>
													</div>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-card no-body class="mt-3" footer-tag="footer">
												<b-tabs v-model="upperTabIndex" card>
													<!-- Render Tabs, supply a unique `key` to each tab -->
													<b-tab v-for="(c,i) in content.upperCabinets" :key="`tab-upper-${i}`" :title="`Cabinet ${i+1}`">

														<UpperCabinet v-model="content.upperCabinets[i]">
															<div class="text-right mb-3"><b-button v-if="content.upperCabinets.length > 1" variant="outline-danger" @click="() => removeUpperCabinet(i)"><font-awesome-icon icon="minus-square" class="mr-1" /> Remove Cabinet {{i+1}}</b-button></div>
														</UpperCabinet>
													</b-tab>

													<!-- New Tab Button (Using tabs-end slot) -->
													<template #tabs-end v-if="content.upperCabinets.length < 3">
														<b-nav-item @click.prevent="createUpperCabinet" href="#" variant="primary">
															<font-awesome-icon icon="plus-square" size="lg" />
														</b-nav-item>
													</template>

													<!-- Render this if no tabs -->
													<div slot="empty" class="text-center text-muted">
														There are no upper cabinet units <br />
														Create a new cabinet unit using the <font-awesome-icon icon="plus-square"></font-awesome-icon>&nbsp; button above.
													</div>
												</b-tabs>
											</b-card>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
					</div>
					<div role="tablist">
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" header-bg-variant="primary" header-text-variant="white" class="p-1 pl-2" role="tab" v-b-toggle.collapse_loca>
								 <h4 class="my-0">
									<font-awesome-icon icon="caret-down" fixed-width />Lower Cabinets
								 </h4>
							</b-card-header>
							<b-collapse id="collapse_loca" visible role="tabpanel">
								<b-card-body>
									<LowerCabinetStyle v-model="content.lowerCabinetStyle" :cabinets="content.lowerCabinets" />
									<b-row>
										<b-col cols="4">

											<b-form-radio-group id="lowerAccent" v-model="content.lowerAccent" stacked class="mb-3">
												<b-form-radio value="none">No Accent Colour</b-form-radio>
												<b-form-radio value="poly">{{ lowerAccentText }}</b-form-radio>
											</b-form-radio-group>

											<b-form-checkbox id="finished_end" v-model="content.finishedEnd">
												Bottom Finished End
											</b-form-checkbox>

										</b-col>
										<b-col cols="8" class="container">
											<b-row no-gutters>
											<b-col v-for="n in 5" :key="`lower-cabinet-image-${n}`">
												<div v-if="(content.lowerCabinets[n-1])">
													<b-link href="#" @click="lowerTabIndex=n-1">
														<div v-if="(content.lowerAccent === 'none')||((content.lowerCabinetStyle.split('-')[0] === 'impression')&&(content.lowerCabinets[n-1].type === 'hung'))" :style="cabinetNoAccentStyle">
															<b-img v-bind="lowerCabinetProps(n-1)" fluid />
														</div>
														<div v-else :style="cabinetAccentStyle">
															<b-img v-bind="lowerCabinetProps(n-1)" fluid />
														</div>
													</b-link>
												</div>
											</b-col>
											</b-row>
											<b-form-text v-if="content.lowerCabinets.length < minLC" text-variant="warning">Minimum of {{minLC}} Cabinet Units required.</b-form-text>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											 <b-card no-body class="mt-3" footer-tag="footer">
												<b-tabs v-model="lowerTabIndex" card>
													<!-- Render Tabs, supply a unique `key` to each tab -->
													<b-tab v-for="(c,i) in content.lowerCabinets" :key="`tab-lower-${i}`" :title="`Cabinet ${i+1}`">

														<LowerCabinet v-model="content.lowerCabinets[i]" v-on:computed-description="handleLowerCabinetDescription">
															<div class="text-right mb-3"><b-button v-if="content.lowerCabinets.length > minLC" variant="outline-danger" @click="() => removeLowerCabinet(i)"><font-awesome-icon icon="minus-square" class="mr-1" /> Remove Cabinet {{i+1}}</b-button></div>
														</LowerCabinet>
													</b-tab>


													<!-- Remove Cabinet Button (Using tabs slot) -->
													<template #lowerRemove v-if="content.lowerCabinets.length > minLC">
													</template>

													<!-- New Tab Button (Using tabs-end slot) -->
													<template #tabs-end v-if="content.lowerCabinets.length < 5">
													  <b-nav-item @click.prevent="createLowerCabinet" href="#">
														  <font-awesome-icon icon="plus-square" size="lg" />
													  </b-nav-item>
													</template>

													<!-- Render this if no tabs -->
													<div slot="empty" class="text-center text-muted">
													 There are no lower cabinet units <br />
													 Create a new cabinet unit using the <font-awesome-icon icon="plus-square"></font-awesome-icon>&nbsp; button above.
													</div>
												</b-tabs>
												<slot name="lowerTabs">+</slot>
											 </b-card>
										</b-col>
									</b-row>
								</b-card-body>
							</b-collapse>
						</b-card>
					</div>
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<p>Cabinet not found.</p>
		  <p><b-button to="/projects">Back to Projects</b-button></p>
		</div>
	</b-container>
</template>

<script>
// Components
import api from "../api"
import AccentColour from "../components/AccentColour"
import DoorColour from "../components/DoorColour"
import DoorHandle from "../components/DoorHandle"
import Drawer from "../components/Drawer"
import Faucet from "../components/Faucet"
import ImagePicker from "../components/ImagePicker"
import LowerCabinet from "../components/LowerCabinet"
import LowerCabinetStyle from "../components/LowerCabinetStyle"
import LowerCabinetWidth from "../components/LowerCabinetWidth"
import RadioPicker from "../components/RadioPicker"
import UpperCabinet from "../components/UpperCabinet"

// Mixins
import { autosave } from "../mixins/Autosave"

export default {
  components: {
    ImagePicker,
    RadioPicker,
    DoorColour,
    DoorHandle,
    Faucet,
    AccentColour,
    LowerCabinetStyle,
    LowerCabinetWidth,
    LowerCabinet,
    UpperCabinet,
    Drawer
  },
	mixins: [autosave],
  props: {
  	id: Number,
  	projectId: Number
  },
  data() {
    return {
    	content: null,
    	project: null,
      isLoading: true,
      error: {},
      upperTabIndex: 0,
			lowerTabIndex: 0,
			lowerCabinetImages: {
				hung: {
					250 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/hung_250-1.png'),
							alt: '250 mm wide (110/440)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/hung_250-2.png'),
							alt: '250 mm wide (110/220/220)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/hung_250-3.png'),
							alt: '250 mm wide (110/110/330)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/hung_250-4.png'),
							alt: '250 mm wide (110/110/110/220)'
						}
					},
					375 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/hung_375-1.png'),
							alt: '375 mm wide (110/440)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/hung_375-2.png'),
							alt: '375 mm wide (110/220/220)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/hung_375-3.png'),
							alt: '375 mm wide (110/110/330)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/hung_375-4.png'),
							alt: '375 mm wide (110/110/110/220)'
						}
					},
					500 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/hung_500-1.png'),
							alt: '500 mm wide (110/440)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/hung_500-2.png'),
							alt: '500 mm wide (110/220/220)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/hung_500-3.png'),
							alt: '500 mm wide (110/110/330)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/hung_500-4.png'),
							alt: '500 mm wide (110/110/110/220)'
						},
						"5" : {
							src: require('../../../../app/assets/images/lower/hung_500-sink.png'),
							alt: '500 mm wide (sink unit)'
						}
					},
					610 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/hung_610-1.png'),
							alt: '610 mm wide (110/440)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/hung_610-2.png'),
							alt: '610 mm wide (110/220/220)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/hung_610-3.png'),
							alt: '610 mm wide (110/110/330)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/hung_610-4.png'),
							alt: '610 mm wide (110/110/110/220)'
						},
						"5" : {
							src: require('../../../../app/assets/images/lower/hung_610-sink.png'),
							alt: '610 mm wide (sink unit)'
						}
					}
				},
				kickbase: {
					250 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/kickbase_250-1.png'),
							alt: '250 mm wide (110/550)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/kickbase_250-2.png'),
							alt: '250 mm wide (110/220/330)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/kickbase_250-3.png'),
							alt: '250 mm wide (110/110/220/220)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/kickbase_250-4.png'),
							alt: '250 mm wide (110/110/110/330)'
						},
					},
					375 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/kickbase_375-1.png'),
							alt: '375 mm wide (110/550)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/kickbase_375-2.png'),
							alt: '375 mm wide (110/220/330)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/kickbase_375-3.png'),
							alt: '375 mm wide (110/110/220/220)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/kickbase_375-4.png'),
							alt: '375 mm wide (110/110/110/330)'
						},
					},
					500 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/kickbase_500-1.png'),
							alt: '500 mm wide (110/550)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/kickbase_500-2.png'),
							alt: '500 mm wide (110/220/330)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/kickbase_500-3.png'),
							alt: '500 mm wide (110/110/220/220)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/kickbase_500-4.png'),
							alt: '500 mm wide (110/110/110/330)'
						},
						"5" : {
							src: require('../../../../app/assets/images/lower/kickbase_500-sink.png'),
							alt: '500 mm wide (sink unit)'
						}
					},
					610 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/kickbase_610-1.png'),
							alt: '610 mm wide (110/550)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/kickbase_610-2.png'),
							alt: '610 mm wide (110/220/330)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/kickbase_610-3.png'),
							alt: '610 mm wide (110/110/220/220)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/kickbase_610-4.png'),
							alt: '610 mm wide (110/110/110/330)'
						},
						"5" : {
							src: require('../../../../app/assets/images/lower/kickbase_610-sink.png'),
							alt: '610 mm wide (sink unit)'
						}
					}
				},
				tall: {
					250 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						}
					},
					375 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/tall_375-1.png'),
							alt: '375 mm wide (110/660)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/tall_375-2.png'),
							alt: '375 mm wide (330/440)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/tall_375-3.png'),
							alt: '375 mm wide (110/220/440)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/tall_375-4.png'),
							alt: '375 mm wide (110/110/110/440)'
						}
					},
					500 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/tall_500-1.png'),
							alt: '500 mm wide (110/660)'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/tall_500-2.png'),
							alt: '500 mm wide (330/440)'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/tall_500-3.png'),
							alt: '500 mm wide (110/220/440)'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/tall_500-4.png'),
							alt: '500 mm wide (110/110/110/440)'
						}
					},
					610 : {
						"1" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						},
						"2" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						},
						"3" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						},
						"4" : {
							src: require('../../../../app/assets/images/lower/unknown.png'),
							alt: 'unknown cabinet'
						}
					}
				}
			},
			upperCabinetImages: {
				none: {
					500: {
						src: require('../../../../app/assets/images/upper/upper-500.png'),
						alt: '500 mm long'
					},
					1000: {
						src: require('../../../../app/assets/images/upper/upper-1000.png'),
						alt: '1000 mm long'
					},
					1500: {
						src: require('../../../../app/assets/images/upper/upper-1500.png'),
						alt: '1500 mm long'
					}
				},
				poly: {
					500: {
						src: require('../../../../app/assets/images/upper/upper-500.png'),
						alt: '500 mm long'
					},
					1000: {
						src: require('../../../../app/assets/images/upper/upper-1000.png'),
						alt: '1000 mm long'
					},
					1500: {
						src: require('../../../../app/assets/images/upper/upper-1500.png'),
						alt: '1500 mm long'
					}
				},
				logo: {
					500: {
						src: require('../../../../app/assets/images/upper/upper-500-logo.png'),
						alt: '500 mm long with logo'
					},
					1000: {
						src: require('../../../../app/assets/images/upper/upper-1000-logo.png'),
						alt: '1000 mm long with logo'
					},
					1500: {
						src: require('../../../../app/assets/images/upper/upper-1500-logo.png'),
						alt: '1500 mm long with logo'
					}
				}
			},
			locationNames: {
				'left': 'Left Cabinets',
				'rear': 'Rear Cabinets',
				'right': 'Right Cabinets',
			}
		}
  },
  computed: {
  	treatmentCentreName() {
  		if (this.project) {
  			return this.project.treatmentCentres[this.content.n].name
  		}
  	},
  	cabinetNoAccentStyle() {
			return "background-image: url(" + require('../../../../app/assets/images/accent_colours/none.png') + ");"
  	},
		cabinetAccentStyle() {
			let option = this.$store.getters.getDetails('accentColours').find(x => x.id === this.content.accentColour.id)
			console.log("Background src: %s", option.src)
			return "background-image: url(" + option.src + "); background-size: cover;"
		},
		lowerAccentText() {
			if ( this.content.lowerCabinetStyle === 'cloud' ||
					 this.content.lowerCabinetStyle === 'solar') {
				return 'Top Drawer Band Accent'
			} else {
				return 'Tall Cabinet Accent'
			}
		},
		minLC() {
			if ( this.content.lowerCabinetStyle === 'cloud' ||
					 this.content.lowerCabinetStyle === 'solar') {
				return 2
			} else {
				return 3
			}
		}
  },
	methods: {
		upperCabinetProps(n) {
			return this.upperCabinetImages[this.content.upperAccent][this.content.upperCabinets[n-1].width]
  	},
  	lowerCabinetProps(i) {
  		return this.lowerCabinetImages[this.content.lowerCabinets[i].type][this.content.lowerCabinets[i].width][this.content.lowerCabinets[i].specifications]
  	},
		newLowerCabinet(myType) {
			return {
				type: myType,
				width: 500,
				specifications: '1',
				hinge: 'left',
				countertopOptions: 'standard',
				drawerOptions: {
					1: {
						option: 'standard',
						perfectPull: 'no_delivery',
						tray: 4
					},
					2: {
						option: 'standard',
						tray: 4
					},
					3: {
						option: 'standard',
						tray: 4
					},
					4: {
						option: 'standard',
						tray: 4
					}
				}
			}
		},
		newUpperCabinet() {
			return {
				width: 1000,
				dispensingOptions: "none",
				tubTrayOptions: "none"
			}
		},
		removeLowerCabinet(i) {
		  this.content.lowerCabinets.splice(i, 1)
		},
		removeUpperCabinet(i) {
		  this.content.upperCabinets.splice(i, 1)
		},
		createUpperCabinet() {
			this.content.upperCabinets.push(this.newUpperCabinet())
		},
		createLowerCabinet() {
			if (this.content.lowerCabinetStyle === 'solar') {
				this.content.lowerCabinets.push(this.newLowerCabinet('kickbase'))
			} else {
				this.content.lowerCabinets.push(this.newLowerCabinet('hung'))
			}
		},
		setImpressionCabinet(type) {
			var len = this.content.lowerCabinets.length
			for (var i = 0, len; i < len; i++) {
  				this.content.lowerCabinets[i].type = type
			}
			if ((len > 2) && (this.content.lowerCabinetStyle.charAt(0) === 'i')) {
				if (this.content.lowerCabinetStyle === 'impression-left') {
					this.content.lowerCabinets[1].type = 'tall'
				} else {
					this.content.lowerCabinets[len-2].type = 'tall'
				}
			}
		},
		updateBreadCrumbItems() {
			this.$store.dispatch("updateBreadcrumbItems",
				[
					{
						text: 'Projects',
						to: { path: '/projects' }
					},
					{
						text: this.project.projectName,
						to: { path: '/project/' + this.projectId }
					},
					{
						text: this.treatmentCentreName,
						to: { path: '/project/' + this.projectId }
					},
					{
						text: this.locationNames[this.content.location],
						to: { path: '/project/' + this.projectId + '/cabinet/' + this.content.id }
					}
				]
    	)
		},
		handleLowerCabinetDescription(desc) {
			console.log("Lower Cabinet: %o", desc)
		},
  	update() {
  		if (this.content) {
  			api.updateCabinet(this.content)
  		}
  	}
  },
	watch: {
		'content.lowerCabinets'() {
			if (this.content.lowerCabinetStyle === 'solar') {
				this.setImpressionCabinet('kickbase')
			} else {
				this.setImpressionCabinet('hung')
			}
		}
	},
  created() {
		console.log("loading cabinet content for id#%s…", this.id)
		if (this.$store.getters.isAuthenticated) {
			api.getCabinet(this.projectId, this.id).then(response => {
				var cabinet = Object.assign(
					{
						id: response.data.cabinet.id,
						type: response.data.cabinet.cabinet_type,
						projectId: response.data.cabinet.project_id
					},
					JSON.parse(response.data.cabinet.data)
				)
				var project = Object.assign(
					{ id: response.data.project.id },
					JSON.parse(response.data.project.data)
				)
				if (cabinet) {
					this.content = cabinet
					this.project = project
					this.updateBreadCrumbItems()
					if (this.content.upperCabinets.length === 0) {
						this.createUpperCabinet()
					}
					if (this.content.lowerCabinets.length < 2) {
						this.createLowerCabinet()
						this.createLowerCabinet()
					}
				}
				this.isLoading = false
			})
		}
  }
}
</script>

<style scoped lang="scss">
</style>
