<template>
	<b-list-group-item>
		<h5 class="text-capitalize">{{ value.location }} {{ value.type }}</h5>
		<b-card-text>
			<div class="mt-1"><strong>Size: </strong>{{ value.size }} feet</div>
			Door Colour: {{ doorColour.alt }}<br>
			Door Handle: {{ doorHandle.finish }} {{ doorHandle.alt }}<br>
			Faucet: {{ faucet.alt }}
			<div class="mt-1">
				Upper Accent: {{ value.upperAccent }}
				<div v-if="value.upperAccent !== 'none'">
					Accent Colour:
					<span v-if="value.accentColour.id===99">{{ value.accentColour.custom }}</span>
					<span v-else>{{ accentColour.alt }}</span>
				</div>
			</div>
			<div class="mt-1">
				Surface Treatment: <br>{{ this.surfaceTreatments[value.surfaceTreatment] }}
			</div>
		</b-card-text>
	</b-list-group-item>
</template>

<script>
export default {
	props: ['value'],
	data() {
		return {
			surfaceTreatments: this.$store.getters.getOption('island','countertopOptions')
		}
	},
	computed: {
		accentColour() {
			return this.$store.getters.getDetailById('accentColours', this.value.accentColour.id)
		},
		doorHandle() {
			return this.$store.getters.getDetailById('doorHandles', this.value.doorHandle.id)
		},
		doorColour() {
			return this.$store.getters.getDetailById('doorColours', this.value.doorColour.id)
		},
		faucet() {
			return this.$store.getters.getDetailById('faucets', this.value.faucet.id)
		}
	},
	methods: {
		option(category, id) {
			this.$store.getters.getDetailById(category, id)
		}
	},
	mounted: function() {
		let accentColourDescription = ''
		if (this.value.accentColour.id===99) {
			accentColourDescription = 'Custom: (' + this.value.accentColour.custom + ')'
		} else {
			accentColourDescription = this.accentColour.alt
		}
		let pdfData = {
			accentColour: accentColourDescription,
			size: 'Size: ' + this.value.size + ' feet',
			doorColour: this.doorColour.alt,
			doorHandle: this.doorHandle.finish + ' ' + this.doorHandle.alt,
			surfaceTreatment: this.surfaceTreatments[this.value.surfaceTreatment],
			faucet: this.faucet.alt
		}
		this.$emit('pdf', pdfData)
	}
}
</script>

<style scoped lang="scss">
	@import '../assets/main.scss';
	.list-group-item {
		font-size: 80%;
	}
</style>
