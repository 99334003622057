<template>
	<table class="table table-bordered table-sm">
		<tbody>
			<tr v-for="(value, key) in projectInfo">
				<th>{{ titles[key] }}</th>
				<td>{{ value }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	props: ['value'],
	data() {
  	return {
  		content: this.value,
  		titles: {
  			'id': 'ID',
  			'projectName': 'Project',
  			'dealerName': 'Dealer',
  			'location': 'Location',
  			'salesRep': 'Sales Rep',
  			'customerName': 'Customer',
  			'comments': 'Comments',
  			'treatmentCentres': 'Treatment Centres'
  		}
		}
	},
	computed: {
		projectInfo: function () {
			return Object.keys(this.content)
				.filter(k => !['id', 'treatmentCentres'].includes(k))
				.map(k => Object.assign({}, {[k]: this.content[k]}))
				.reduce((res, o) => Object.assign(res, o), {})
		}
	}
}
</script>

<style scoped lang="scss">
</style>
