<template>
  <header>
    <nav class="navbar navbar-expand navbar-primary">
      <div class="container">
      	<ul class="navbar-nav">
          <li class="nav-item" v-for="(b,i) in breadcrumbItems">
            <font-awesome-icon v-if="i>0" icon="chevron-right" class="mr-2" />
            <router-link :to="b.to.path">
            	{{ b.text }}
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <template v-if="isAuthenticated">
            <li class="nav-item">
              <a @click="signOut">Sign Out</a>
            </li>
            <li class="nav-item user">
              <font-awesome-icon icon="user" size="sm" />
              {{ username }}
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link to="/sign-in">Sign In</router-link>
            </li>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    username() {
      return this.$store.state.user.username;
    },
    breadcrumbItems() {
    	return this.$store.state.breadcrumbItems;
    }
  },
  methods: {
    signOut: function() {
      this.$store.dispatch("signOut").then(() => {
        this.$router.push("sign-in");
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '../assets/main.scss';
.nav-item {
  a {
    color: $brand-text-color !important;
    opacity: 0.7;
    text-decoration: none;
    padding-bottom: 2px;

    &.active,
    &:hover {
      color: $brand-text-color;
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
    }
  }
  .fa-chevron-right {
    color: $muted-text-color !important;
    padding-bottom: 3px;
  }
}

.navbar-primary {
  background-color: $nav-primary-color;
  padding-bottom: 15px;

  .nav-item {
    margin-right: 15px;

    a {
      font-size: 1rem;
    }

    &.user {
      color: $muted-text-color;
    }
  }
}

.navbar-secondary {
  background-color: $nav-secondary-color;
  padding-bottom: 25px;

  .nav-item {
    margin: 0 15px;
    a {
      font-size: 1.5rem;
      padding: 0;
    }
  }

  .badge {
    font-size: 0.85rem;
    font-weight: 400;
    color: $primary-text-color;
    background-color: $green-accent-color;
    vertical-align: top;
    margin-left: 1px;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
}

a.router-link-exact-active {
  border-bottom: 2px solid #94d31b;
  opacity: 1;
}

.brand {
  text-align: center;
  background-color: $nav-secondary-color;
  padding: 10px 0 10px 0;

  .logo {
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
  }

  .name {
    font-size: 2.2rem;
    font-weight: 600;
    display: inline-block;
    color: $brand-text-color;
  }
}
</style>
