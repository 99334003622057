<template>
	<b-card no-body class="mb-1">
		<b-card-header v-b-toggle="this.collapseId" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="align-baseline p-1 pl-2" role="tab">
			<font-awesome-icon icon="caret-down" fixed-width />
		Dispensing <span class="float-right mr-2"><small>{{ this.dispensingOptions[this.value] }}</small></span>
		</b-card-header>
		<b-collapse :id="collapseId" visible role="tabpanel">
			<b-card-body>
				<b-form-radio-group v-model="selected" :options="this.options" stacked />
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
export default {
  props: ['value', 'width'],
	data() {
		return {
			dispensingOptions: this.$store.getters.getOption('upperCabinet', 'dispensingOptions'),
		  selected: this.value,
		  collapseId: 'dispensing'
		}
	},
	computed: {
		options() {
			if (this.width === 500) {
				return [
					{text: 'No Dispensing', value: 'none'},
					{text: 'Soap & Sanitary with Paper', value: 'soap_paper'},
					{text: 'Soap & Sanitary with Cup', value: 'soap_cup'}
			 	]
			} else {
				return [
					{text: 'No Dispensing', value: 'none'},
					{text: 'Wet Dispensing', value: 'wet'},
					{text: 'Dry Dispensing', value: 'dry'}
			 	]
			}
		}
	},
	watch: {
		selected() {
			this.$emit('input', this.selected);
		}
	}
}
</script>

<style scoped lang="scss">
</style>
