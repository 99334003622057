import Vue from "vue";
import Router from "vue-router";

import Signin from "./views/Signin";
import User from "./views/User";
import Project from "./views/Project.vue";
import Cabinet from "./views/Cabinet.vue";
import Island from "./views/Island.vue";
import store from "./store.js";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: "/app",
  routes: [
    {
      path: "/sign-in",
      component: Signin
    },
  	{
  		path: "/projects",
  		component: User,
      beforeEnter: requireAuth
  	},
  	{
  		path: "/videos",
  		redirect: '/app/videos'
  	},
    {
      path: "/project/:id",
      component: Project,
      props(route) {
      	const props = { ...route.params }
      	props.id = +props.id
      	return props
      },
			beforeRouteLeave (to, from , next) {
				const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
				if (answer) {
					next()
				} else {
					next(false)
				}
			}
    },
    {
      path: "/project/:projectId/cabinet/:id",
      component: Cabinet,
      props(route) {
      	const props = { ...route.params }
      	props.projectId = +props.projectId
      	props.id = +props.id
      	return props
      }
    },
    {
      path: "/project/:projectId/island/:id",
      component: Island,
      props(route) {
      	const props = { ...route.params }
      	props.projectId = + props.projectId
      	props.id = +props.id
      	return props
      }
    },
    {
      path: "*",
      redirect: "/projects"
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
})


function requireAuth(to, from, next) {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.fullPath }
    });
  }
}

