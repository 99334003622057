<template>
    <b-form-group class="mb-3">
      <b-form-radio-group
        id="Lower_Cabinet_Style"
        buttons
        button-variant="outline-primary"
        size="md"
        v-model="selected"
        :options="options"
        name="lowerCabinetRadio"
      />
    </b-form-group>
</template>

<script>
export default {
  props: ['value', 'cabinets'],
	data() {
		return {
		  selected: this.value,
			options: [
				{text: 'Cloud', value: 'cloud'},
				{text: 'Impression (Left)', value: 'impression-left'},
				{text: 'Impression (Right)', value: 'impression-right'},
				{text: 'Solar', value: 'solar'}
			]
		}
	},
	watch: {
		selected() {
			if (this.selected === 'solar') {
				this.setAllCabinetTypes('kickbase')
			} else {
				this.setAllCabinetTypes('hung')
			}
			if (this.selected === 'impression-left') {
				if (this.cabinets[1]) {
					this.cabinets[1].type = 'tall'
				}
			} else if (this.selected === 'impression-right') {
				if (this.cabinets[this.cabinets.length-2]) {
					this.cabinets[this.cabinets.length-2].type = 'tall'
				}
			}
			this.$emit('input', this.selected);
		}
	},
	methods: {
		setAllCabinetTypes(type) {
			for (var i = 0, len = this.cabinets.length; i < len; i++) {
  			this.cabinets[i].type = type
			}
		}
	},
	computed: {
		islandSrc() {
			return this.options.find(x => x.value === this.selected).src;
		}
	}
}
</script>
