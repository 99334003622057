<template>
	<b-container>
		<div v-if="content">
			<b-button @click="update" variant="success" class="float-right">Save Changes</b-button>
			<h2 class="mb-4">
				Edit {{ this.locationNames[this.content.location] }}
				<small class="text-muted ml-2">for {{ this.treatmentCentreName }}</small>
			</h2>
			<b-row>
				<b-col cols="4">
					<DoorColour v-model="content.doorColour" />
					<AccentColour v-model="content.accentColour" :title="'Accent Colour'" />
					<RadioPicker v-model="content.upperAccent" :options=accentOptions :title=accentOptionsTitle />
					<DoorHandle v-model="content.doorHandle" />
					<Faucet v-model="content.faucet" />
				</b-col>
				<b-col cols="8">
					<IslandSize v-model="content.size"/>
					<RadioPicker v-model="content.surfaceTreatment" :options=surfaceOptions :title=surfaceOptionsTitle />
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<p>Island not found.</p>
		  <p><b-button to="/projects">Back to Projects</b-button></p>
		</div>
	</b-container>
</template>

<script>
// Components
import api from "../api"
import ImagePicker from "../components/ImagePicker"
import DoorColour from "../components/DoorColour"
import DoorHandle from "../components/DoorHandle"
import Faucet from "../components/Faucet"
import AccentColour from "../components/AccentColour"
import RadioPicker from "../components/RadioPicker"
import IslandSize from "../components/IslandSize"

// Mixins
import { autosave } from "../mixins/Autosave"

export default {
  components: {
  	AccentColour,
  	DoorColour,
  	DoorHandle,
  	Faucet,
    ImagePicker,
    RadioPicker,
    IslandSize
  },
	mixins: [autosave],
  props: {
  	id: Number,
  	projectId: Number
  },
  data() {
    return {
    	content: null,
    	project: null,
      isLoading: true,
      error: {},
			doorMaterialTitle: 'Door Material',
			doorMaterialOptions: [
				{text: 'Gloss Finish', value: 'gloss'},
				{text: 'Matte Finish', value: 'matte'}
			],
			accentOptionsTitle: 'Accent Options',
			accentOptions: [
				{text: 'No Upper Accent Colour', value: 'none'},
				{text: 'Poly Upper Accent', value: 'poly'}
			],
			surfaceOptionsTitle: 'Surface Treatment',
			surfaceOptions: [
				{text: 'Solid Surface Standard White', value: 'standard'},
				{text: 'Solid Surface with Bright White Sink', value: 'white_sink'},
				{text: 'Stainless Steel 12" Round Sink', value: 'ss_sink'}
			],
      locationNames: {
				'left': 'Left Island',
				'right': 'Right Island'
			}
    }
  },
  methods: {
		updateBreadCrumbItems() {
			this.$store.dispatch("updateBreadcrumbItems",
				[
					{
						text: 'Projects',
						to: { path: '/projects' }
					},
					{
						text: this.project.projectName,
						to: { path: '/project/' + this.projectId }
					},
					{
						text: this.treatmentCentreName,
						to: { path: '/project/' + this.projectId }
					},
					{
						text: this.locationNames[this.content.location],
						to: { path: '/project/' + this.projectId + '/island/' + this.content.id }
					}
				]
    	)
		},
  	update() {
  		if (this.content) {
  			api.updateCabinet(this.content)
  		}
  	}
  },
  computed: {
		treatmentCentreName() {
			if (this.project) {
				return this.project.treatmentCentres[this.content.n].name
			}
		}
	},
  created() {
		console.log("loading island content for id#%s…", this.id)
		if (this.$store.getters.isAuthenticated) {
			api.getCabinet(this.projectId, this.id).then(response => {
				console.log("island response: %o", response)
				var island = Object.assign(
					{
						id: response.data.cabinet.id,
						type: response.data.cabinet.cabinet_type,
						projectId: response.data.cabinet.project_id
					},
						JSON.parse(response.data.cabinet.data)
				)
				var project = Object.assign(
					{ id: response.data.project.id },
					JSON.parse(response.data.project.data)
				)
				if (island) {
					this.content = island
					this.project = project
					this.updateBreadCrumbItems()
				}
				this.isLoading = false
			})
		}
  }
}
</script>

<style scoped lang="scss">
</style>
