<% helpers = ActionController::Base.helpers %>
<template>
	<b-card no-body class="mb-2">
		<b-card-header v-b-toggle.collapseAccentColour role="tab" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2" variant="info">
			<font-awesome-icon icon="caret-down" fixed-width />
		Accent Colour <span class="float-right mr-2"><small>{{ accentColour }}</small></span>
		</b-card-header>
		<b-collapse id="collapseAccentColour" visible role="tabpanel">
			<b-card-body class="p-2">
				<b-form-group>
					<ImagePicker
						v-model="selected.id"
						:selectedImages=selectedImage
						:images=colourOptions
						:custom=true placeholder="custom accent colour"
						:width="'95px'"
						@update="onCustomColourUpdate"
					/>
				</b-form-group>
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
import ImagePicker from "../components/ImagePicker"

export default {
  components: {
    ImagePicker
  },
  props: ['value'],
	data() {
		return {
		  selected: this.value,
		  accentColour: '',
		  selectedImage: [this.$store.getters.getDetails('accentColours').find(x => x.id === this.value.id)],
		  colourOptions: this.$store.getters.getDetails('accentColours')
		}
	},
	methods: {
		onColourUpdate(newId) {
			if (newId === 99) {
				this.accentColour = 'Custom: ' + this.selected.custom
			} else {
				let option = this.colourOptions.find(x => x.id === newId)
				this.accentColour = option.alt
				delete this.selected.custom
			}
		},
		onCustomColourUpdate(newColour) {
			this.selected.custom = newColour
			this.accentColour = 'Custom: ' + newColour
		},
		getColour(id) {
			return this.$store.getters.getDetailById('accentColours', id)
		}
	},
	mounted: function() {
		this.onColourUpdate(this.selected.id)
	},
	watch: {
		'selected.id': function(newValue, oldValue) {
			this.onColourUpdate(newValue)
		}
	}
}
</script>

<style lang="scss">
	@import '../assets/main.scss';
	#collapseAccentColour .vue-select-image .vue-select-image__item {
	  width: 103px;
	  .vue-select-image__thumbnail {
	  	text-align: center;
	  }
  }
</style>
