<template>
	<b-container>
		<h2>Projects</h2>
		<div v-show="isLoading" class="spinner">
			<font-awesome-icon icon="spinner" size="3x" class="m-2" spin />
    </div>
		<div v-if="isEmpty">
			<p>No projects for this user</p>
		</div>
		<div v-else>
			<b-table striped hover :items="projects" :fields="fields" id="project_table">
				<template #cell(actions)="data">
					<router-link :to="'/project/'+data.item.id" class="btn btn-sm btn-primary">
						Edit
					</router-link>
					<b-button @click="() => copyProject(data.index)" variant="info" size="sm">
						Copy
					</b-button>
					<b-button @click="() => deleteProject(data.item.id)" variant="outline-danger" size="sm">
						Delete
					</b-button>
				</template>
			</b-table>
		</div>
		<b-button @click="createProject" variant="primary large">
			<font-awesome-icon icon="plus-square" size="lg" class="mr-2" />
			Create a Project
		</b-button>
		<b-button v-b-modal.import_project variant="info large">
			<font-awesome-icon icon="clipboard" size="lg" class="mr-2" />
			Import a Project
		</b-button>
		<b-modal
			id="import_project"
			ref="modal"
			title="Import Project"
			footer-bg-variant="light"
			okTitle="Import"
			@ok="importProject"
		>
			<b-form class="mb-4">
				<p>Import another user’s project, or duplicate one of your own projects using the project export text copied with the buttons on this page.</p>
				<div>
					<b-form-textarea
						id="import_project_string"
						v-model="importString"
						placeholder="Paste project export text here..."
						rows="3"
						max-rows="10"
					/>
    		</div>
			</b-form>
		</b-modal>
		<h2 class="mt-4">Installation Videos</h2>
		<a href="/app/videos" class="btn btn-success" target="_blank">Watch Videos</a>
	</b-container>
</template>

<script>
import api from "../api"

// Lz-string compression
import { compressToBase64, decompressFromBase64 } from 'lz-string'

export default {
	data() {
		return {
			errors: {},
			fields: [
				{
					key: 'projectName',
					sortable: true
				},
				{
					key: 'dealerName',
					sortable: true
				},
				{
					key: 'location',
					sortable: true
				},
				{
					key: 'salesRep',
					sortable: true
				},
				{
					key: 'customerName',
					sortable: false
				},
				'actions'
			],
			importString: '',
			isLoading: true
		}
	},
  created() {
  	this.$store.dispatch("getProjects").then(response => {
  		this.isLoading = false
  	})
  },
  computed: {
  	projects() {
  		return this.$store.state.projects
  	},
  	isEmpty() {
  		return this.projects.length == 0
  	}
  },
	methods: {
		createProject() {
			api
				.createProject(this.newProject())
				.then(response => {
					this.$router.go()
				})
				.catch(error => {
					this.errors = error.response.data.errors
				})
		},
		duplicateCabinet(newId, oldId, cabinetId, callback) {
			api
				.getCabinet(oldId, cabinetId)
				.then(response => {
					var cabinet = JSON.parse(response.data.cabinet.data)
					delete cabinet.id
					console.log("Cabinet: %o", cabinet)

					api.createCabinet(newId, cabinet.type, cabinet)
						.then(response => {
							console.log("Response: %o", response.data.id)
							callback(response.data.id)
						})
						.catch(error => {
							console.log("OMG ERROR: %o", error)
							// this.errors = error.response.data.errors
						})
				})
				.catch(error => {
					console.log("OMG ERROR: %o", error)
					// this.errors = error.response.data.errors
				})
		},
    importProject() {
    	var projectData = JSON.parse(decompressFromBase64(this.importString))
    	var oldId = projectData.project.id
    	delete projectData.project.id
    	console.log("Creating Project: %o", projectData.project)
    	api
    		.createProject(projectData.project)
				.then(cp_response => {

					// TODO: Dancing around weird API output from rails
					var newProject = JSON.parse(cp_response.data.data)
					newProject.id = cp_response.data.id
    			console.log("New Project: %o", newProject)

					for (var tci in newProject.treatmentCentres) {
						if (newProject.treatmentCentres[tci].left.id !== 0) {
							// Duplicate left cabinet
							this.duplicateCabinet(newProject.id, oldId, newProject.treatmentCentres[tci].left.id, newId => {
								newProject.treatmentCentres[tci].left.id = newId
								api.updateProject(newProject)
							})
						}
						if (newProject.treatmentCentres[tci].rear.id !== 0) {
							// Duplicate rear cabinet
							this.duplicateCabinet(newProject.id, oldId, newProject.treatmentCentres[tci].rear.id, newId => {
								newProject.treatmentCentres[tci].rear.id = newId
								api.updateProject(newProject)
							})
						}
						if (newProject.treatmentCentres[tci].right.id !== 0) {
							// Duplicate right cabinet
							this.duplicateCabinet(newProject.id, oldId, newProject.treatmentCentres[tci].right.id, newId => {
								newProject.treatmentCentres[tci].right.id = newId
								api.updateProject(newProject)
							})
						}
					}
					this.$router.go()
				})
				.catch(error => {
					console.log("OMG ERROR: %o", error)
					// this.errors = error.response.data.errors
				})
    },
    newProject() {
      return {
        projectName: "New Project",
        dealerName: "",
        location: "",
        salesRep: "",
        customerName: "",
        comments: "",
        treatmentCentres: [{
        	name: 'Treatment Centre 1',
        	left: {
        		type: 'none',
        		id: 0
        	},
        	rear: {
        		type: 'none',
        		id: 0
        	},
        	right: {
        		type: 'none',
        		id: 0
        	}
      	}]
      }
    },
  	copyProject(index) {
  		var projectString = compressToBase64(JSON.stringify({project: this.projects[index]}))
  		this.$clipboard(projectString)
  		this.$bvToast.toast('Project export text successfully copied to clipboard.', {
          title: 'Copied!',
          variant: 'success'
      })
  	},
    deleteProject(id) {
    	api.deleteProject(id).then(response => {
				this.$router.go()
			}).catch(error => {
				this.errors = error.response.data.errors
			})
    }
	}
}
</script>

<style lang="scss">
</style>
