<template>
	<b-card no-body class="mb-1">
		<b-card-header v-b-toggle="this.collapseId" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="align-baseline p-1 pl-2" role="tab">
			<font-awesome-icon icon="caret-down" fixed-width />
		{{ this.title }} <span class="float-right mr-2"><small>{{ this.options.find(x => x.value === this.value).text }}</small></span>
		</b-card-header>
		<b-collapse :id="collapseId" visible role="tabpanel">
			<b-card-body>
				<b-form-radio-group v-model="selected" :options="options" stacked />
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
export default {
  props: ['value', 'options', 'title'],
	data() {
		return {
		    selected: this.value,
		    collapseId: this.title.replace(/\W/g,'_')
		}
	},
	watch: {
		selected() {
			this.$emit('input', this.selected);
		}
	}
}
</script>

<style scoped lang="scss">
</style>
