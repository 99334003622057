<template>
	<div>
		<b-row>
			<b-col cols="7">
				<UpperCabinetSize v-model="content.width"/>
			</b-col>
			<b-col cols="5">
				<slot></slot>
			</b-col>
		</b-row>

		<b-form-text id="upperCabinetHelp" class="mb-3">
			<strong>Note: </strong>Uppers come with one adjustable shelf unless otherwise specified.
		</b-form-text>

		<b-row>
			<b-col cols="5">
				<Dispensing v-model="content.dispensingOptions" :width="content.width" />
			</b-col>
			<b-col cols="7">
				<TubTray v-model="content.tubTrayOptions"  :width="content.width" />
			</b-col>
		</b-row>

		<b-form-text id="upperCabinetHelp">
			<strong>Note: </strong>Lower section of tray storage is lost when combining dispensing with tub and tray options.
		</b-form-text>
	</div>
</template>

<script>
import UpperCabinetSize from "../components/UpperCabinetSize"
import Dispensing from "../components/Dispensing"
import RadioPicker from "../components/RadioPicker"
import TubTray from "../components/TubTray"

export default {
	props: ['value'],
	components: {
		Dispensing,
		UpperCabinetSize,
		RadioPicker,
		TubTray
	},
	data() {
		return {
			content: this.value
		}
	},
	methods: {
		handleInput(e) {
			this.$emit('input', this.content)
		}
	}
}

</script>
