<template>
	<b-card no-body class="mb-2">
		<b-card-header v-b-toggle.collapseFaucet role="tab" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2">
			<font-awesome-icon icon="caret-down" fixed-width /> Faucet <span class="float-right mr-2"><small>{{ faucet }}</small></span>
		</b-card-header>
		<b-collapse id="collapseFaucet" visible role="tabpanel">
			<b-card-body class="p-2">
				<b-form-group class="mb-2">
					<b-form-radio-group
						id="Faucet_Finish"
						buttons
						button-variant="outline-primary"
						size="sm"
						v-model="finish"
						:options="finishOptions"
					/>
				</b-form-group>

				<b-form-group>
					<ImagePicker
						v-model="selected.id"
						:selectedImages=selectedImage
						:width="'150px'"
						:images=groupedFaucetOptions[finish]
					/>
				</b-form-group>
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
import ImagePicker from "../components/ImagePicker"

export default {
  components: {
    ImagePicker
  },
	props: ['value'],
  data() {
    return {
    	selected: this.value,
    	faucet: '',
    	finish: '',
		  selectedImage: [this.$store.getters.getDetails('faucets').find(x => x.id === this.value.id)],
    	faucetOptions: this.$store.getters.getDetails('faucets'),
    	groupedFaucetOptions: this.$store.getters.getGroupedDetails('faucets','finish'),
    	finishOptions: [
				{ text: 'Brushed Satin Nickel', value: 'nickel' },
				{ text: 'Polished Chrome', value: 'chrome' }
    	]
		}
  },
	methods: {
		onFaucetUpdate(newId) {
			let option = this.faucetOptions.find(x => x.id === newId)
			this.finish = option.finish
			this.faucet = option.alt
		}
	},
	mounted: function() {
		this.onFaucetUpdate(this.selected.id)
	},
	watch: {
		'selected.id'() {
			this.onFaucetUpdate(this.selected.id)
		}
	}
}
</script>

<style lang="scss">
	@import '../assets/main.scss';
	#collapseFaucet .vue-select-image .vue-select-image__item {
	  width: 150px;
	  .vue-select-image__thumbnail {
	  	text-align: center;
	  }
  }
</style>
