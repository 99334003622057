<template>
    <b-form-group>
      <b-form-radio-group
        id="Lower_Cabinet_Width"
        buttons
        button-variant="outline-primary"
        size="md"
        v-model="selected"
        :options="options"
      />
    </b-form-group>
</template>

<script>
export default {
  props: ['value', 'cabinet'],
	data() {
		return {
		  selected: this.value,
			options: [
				{text: '250 mm', value: 250},
				{text: '375 mm', value: 375},
				{text: '500 mm', value: 500},
				{text: '610 mm', value: 610}
			]
		}
	},
	watch: {
		selected() {
			if ((this.selected < 500) && (this.cabinet.specifications === '5')) {
				// Sink Units can only be 500 or 610 mm wide. Change to drawer and door.
				this.cabinet.specifications = '1'
			}
			this.$emit('input', this.selected)
		}
	},
	computed: {
		islandSrc() {
			return this.options.find(x => x.value === this.selected).src
		}
	}
}
</script>
