<template>
  <div id="app">
    <Header/>
    <div id="content" class="my-4">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  components: {
    Header,
    Footer
  },
  beforeRouteUpdate (to, from, next) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    next()
  },
  message: "Hello Vue!"
}
</script>

<style scoped>
p { font-size: 2em; text-align: center; }
</style>
