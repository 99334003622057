<template>
	<b-card no-body class="mt-2">
		<b-card-header v-b-toggle="collapseId" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2" role="tab" variant="info">
			<font-awesome-icon icon="caret-down" fixed-width />
				{{ this.content.name }}
		</b-card-header>

		<b-collapse :id="collapseId" visible role="tabpanel">
			<b-list-group flush>
				<CabinetSummary v-if="content.left.type === 'cabinet'" v-model="this.left" @pdf="leftPdf($event)" />
				<IslandSummary v-if="content.left.type === 'island'" v-model="this.left" @pdf="leftPdf($event)" />
				<CabinetSummary v-if="content.rear.type === 'cabinet'" v-model="this.rear" @pdf="rearPdf($event)" />
				<IslandSummary v-if="content.rear.type === 'island'" v-model="this.rear" @pdf="rearPdf($event)" />
				<CabinetSummary v-if="content.right.type === 'cabinet'" v-model="this.right" @pdf="rightPdf($event)" />
				<IslandSummary v-if="content.right.type === 'island'" v-model="this.right" @pdf="rightPdf($event)" />
			</b-list-group>
		</b-collapse>
	</b-card>
</template>

<script>
import CabinetSummary from "../components/CabinetSummary"
import IslandSummary from "../components/IslandSummary"

export default {
	components: {
		CabinetSummary,
		IslandSummary
	},
	props: ['value', 'left', 'rear', 'right'],
	data() {
  	return {
  		content: this.value,
  		collapseId: this.value.name.replace(/\W/g,'_')
		}
	},
	methods: {
		leftPdf (pdfData) {
			this.$emit('leftPdf', pdfData)
		},
		rearPdf (pdfData) {
			this.$emit('rearPdf', pdfData)
		},
		rightPdf (pdfData) {
			this.$emit('rightPdf', pdfData)
		}
	}
}
</script>

<style scoped lang="scss">
</style>
