export const autosave = {
	data: function () {
		return {
      lastUpdate: Date.now()
		}
	},
	watch: {
		content: {
			deep: true,
			handler () {
				var now = Date.now()
				if (now - this.lastUpdate > 6000) {
					console.log("Autosaving…")
					this.update()
					this.lastUpdate = now
				}
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		this.update()
		this.$bvToast.toast('Changes saved.', {
      title: 'Save',
      variant: 'success'
    })
		next()
	}
}
