/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'
import App from './App'
import router from './router'
import store from "./store"

import BootstrapVue from 'bootstrap-vue'
import './assets/main.scss'

import Dayjs from 'vue-dayjs'
import Clipboard from 'v-clipboard'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faCaretRight, faCaretDown, faMinusSquare, faPlusSquare, faChevronRight, faSpinner, faUser, faHome, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueSelectImage from 'vue-select-image'
require('vue-select-image/dist/vue-select-image.css')

library.add(
	faCaretRight,
	faCaretDown,
	faMinusSquare,
	faPlusSquare,
	faChevronRight,
	faUser,
	faHome,
	faClipboard,
	faSpinner
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.component('font-awesome-layers', FontAwesomeLayers)
// Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.config.productionTip = false

Vue.use(Dayjs, {lang: 'en'})
Vue.use(BootstrapVue)
Vue.use(VueSelectImage)
Vue.use(Clipboard)

const app = new Vue ({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
