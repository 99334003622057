<template>
    <b-form-group>
      <b-form-radio-group
        id="Upper_Cabinet_Size"
        buttons
        button-variant="outline-primary"
        size="md"
        v-model="selected"
        :options="options"
      />
    </b-form-group>
</template>

<script>
export default {
  props: ['value'],
	data() {
		return {
		  selected: this.value,
			options: [
				{text: '500 mm', value: 500},
				{text: '1000 mm', value: 1000},
				{text: '1500 mm', value: 1500}
			]
		}
	},
	watch: {
		selected() {
			this.$emit('input', this.selected);
		}
	}
}
</script>
