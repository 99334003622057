<template>
	<div>
		<vue-select-image @onselectimage="imageSelected" :dataImages="images" :selectedImages="selectedImages" :useLabel="true" :w="width" />
		<b-form-input v-if="customSelected" v-model="customValue" trim type="text" id="custom_value" :placeholder="placeholder" />
	</div>
</template>

<script>
export default {
  props: {
    value: Number,
    images: Array,
    selectedImages: Array,
    title: String,
    width: String,
    custom: Boolean,
    placeholder: String
  },
  data() {
    return {
      selected: this.value,
      customValue: ''
		}
  },
  methods: {
  	imageSelected(data) {
  	   this.selected = data.id;
  	}
  },
  computed: {
    customSelected() {
    	return (this.selected === 99)
    }
  },
  watch: {
		selected() {
			this.$emit('input', this.selected)
			this.$emit('update', this.customValue)
		},
		customValue() {
			this.$emit('update', this.customValue)
		}
  }
};
</script>

<style scoped lang="scss">
</style>
