<% helpers = ActionController::Base.helpers %>
<template>
  <b-card no-body class="mb-2">
    <b-card-header v-b-toggle.collapseDoorColour role="tab" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2"  >
      <font-awesome-icon icon="caret-down" fixed-width />
      Door Colour <span class="float-right mr-2"><small>{{ doorColour }}</small></span>
    </b-card-header>
    <b-collapse id="collapseDoorColour" visible role="tabpanel">
      <b-card-body class="p-2">
				<b-form-group class="mb-2">
					<b-form-radio-group
						id="Door_Colour_Finish"
						buttons
						button-variant="outline-primary"
						size="sm"
						v-model="finish"
						:options="finishOptions"
					/>
				</b-form-group>
				<b-form-group>
					<ImagePicker
						v-model="selected.id"
						:selectedImages=selectedImage
						:width="'68px'"
						:images=groupedColourOptions[finish]
					/>
				</b-form-group>
			</b-card-body>
		</b-collapse>
	</b-card>
</template>

<script>
import ImagePicker from "../components/ImagePicker"

export default {
  components: {
    ImagePicker
  },
  props: ['value'],
	data() {
		return {
		  selected: this.value,
		  finish: '',
		  doorColour: '',
		  selectedImage: [this.$store.getters.getDetails('doorColours').find(x => x.id === this.value.id)],
		  colourOptions: this.$store.getters.getDetails('doorColours'),
		  groupedColourOptions: this.$store.getters.getGroupedDetails('doorColours','finish'),
			finishOptions: [
				{ text: 'Gloss Finish', value: 'gloss' },
				{ text: 'Matte Finish', value: 'matte' }
			]
		}
	},
	methods: {
		onColourUpdate(newId) {
			let option = this.colourOptions.find(x => x.id === newId)
			this.finish = option.finish
			this.doorColour = option.alt
		}
	},
	mounted: function() {
		this.onColourUpdate(this.selected.id)
	},
	watch: {
		'selected.id'() {
			this.onColourUpdate(this.selected.id)
		}
	}
}
</script>

<style lang="scss">
	@import '../assets/main.scss';
	#collapseDoorColour .vue-select-image .vue-select-image__item {
	  width: 75px;
	  .vue-select-image__thumbnail {
	  	text-align: center;
			label { height: 32px; }
	  }
  }
</style>
