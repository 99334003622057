<template>
	<b-container class="lowerCabinet">
		<b-row>
			<b-col cols="7">
				<LowerCabinetWidth v-model="content.width" class="mb-3" :cabinet="this.content" />
			</b-col>
			<b-col cols="5">
				<slot></slot>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<div v-if="validCabinet()">
					<b-card v-if="content.type" no-body class="mb-1" id="specifications_card">
						<b-card-header v-b-toggle.Lower_Cabinet_Specifications header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2" role="tab" variant="info">
							<font-awesome-icon icon="caret-down" fixed-width />
								Specifications
						</b-card-header>
						<b-collapse :id="'specifications-'+content.id" visible role="tabpanel">
							<b-card-body>
								<b-form-radio-group v-model="content.specifications" stacked>
									<b-form-radio value="1">
										<span v-html="cabinetSpecificationText[content.type]['1']"></span>
									</b-form-radio>
									<b-form-select v-if="content.specifications === '1'" v-model="content.hinge" :options=hingeOptions size="sm" />
									<b-form-radio value="2">
										<span v-html="cabinetSpecificationText[content.type]['2']"></span>
									</b-form-radio>
									<b-form-radio value="3">
										<span v-html="cabinetSpecificationText[content.type]['3']"></span>
									</b-form-radio>
									<b-form-radio value="4">
										<span v-html="cabinetSpecificationText[content.type]['4']"></span>
									</b-form-radio>
									<b-form-radio value="5" v-if="(content.width > 375)&&(content.type !== 'tall')">
										<span v-html="cabinetSpecificationText[content.type]['5']"></span>
									</b-form-radio>
								</b-form-radio-group>
							</b-card-body>
						</b-collapse>
					</b-card>

					<b-card no-body class="mb-1" id="countertop_card">
						<b-card-header v-b-toggle.Lower_Cabinet_Specifications header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2" role="tab" variant="info">
							<font-awesome-icon icon="caret-down" fixed-width />
								Countertop
						</b-card-header>
						<b-collapse :id="'countertop-'+content.id" visible role="tabpanel">
							<b-card-body>
								<b-form-radio-group v-model="content.countertopOptions" stacked>
									<b-form-radio value='standard' selected :disabled="content.specifications == '5'">
										Solid Surface, Standard White
									</b-form-radio>
									<b-form-radio value='round_sink' v-if="content.specifications === '5'">
										Solid Surface with Round Sink
									</b-form-radio>
									<b-form-radio value='square_sink' v-if="content.specifications === '5'">
										Solid Surface with Square Sink
									</b-form-radio>
								</b-form-radio-group>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-col>

			<b-col>
				<div v-if="validCabinet()">
					<Drawer v-if="content.specifications !== '5'" v-model="content.drawerOptions[1]" :cabinet="content" :title="drawerTitle(1)" :location="1" :size="drawerSizes[content.type][content.specifications][0]" @update="onDrawerUpdate" />
					<Drawer v-if="content.specifications !== '5'" v-model="content.drawerOptions[2]" :cabinet="content" :title="drawerTitle(2)" :location="2" :bottom="drawerSizes[content.type][content.specifications].length===2" :size="drawerSizes[content.type][content.specifications][1]" />
					<Drawer v-if="drawerSizes[content.type][content.specifications].length > 2" v-model="content.drawerOptions[3]" :cabinet="content" :title="drawerTitle(3)" :location="3" :bottom="drawerSizes[content.type][content.specifications].length===3" :size="drawerSizes[content.type][content.specifications][2]" />
					<Drawer v-if="drawerSizes[content.type][content.specifications].length > 3" v-model="content.drawerOptions[4]" :cabinet="content" :title="drawerTitle(4)" :location="4" :bottom="drawerSizes[content.type][content.specifications].length===4" :size="drawerSizes[content.type][content.specifications][3]" />
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import Drawer from "../components/Drawer"
import LowerCabinetWidth from "../components/LowerCabinetWidth"
import RadioPicker from "../components/RadioPicker"

export default {
	props: ['value'],
	components: {
		Drawer,
		LowerCabinetWidth,
		RadioPicker
	},
	data() {
		return {
			content: this.value,
			countertopSpecifications: [
				{text: 'Solid Surface Standard White', value: 1},
				{text: 'Solid Surface with Bright White Sink', value: 2},
				{text: 'Stainless Steel 12" Round Sink', value: 3}
			],
			cabinetSpecificationText: this.$store.getters.getOption('lowerCabinet','specificationText'),
			drawerSizes: {
				kickbase: {
					'1': ['110', 'Door'],
					'2': ['110','220','330'],
					'3': ['110','110','220','220'],
					'4': ['110','110','110','330'],
					'5': []
				},
				hung: {
					'1': ['110', 'Door'],
					'2': ['110','220','220'],
					'3': ['110','110','330'],
					'4': ['110','110','110','220'],
					'5': []
				},
				tall: {
					'1': ['110', 'Door'],
					'2': ['330','440'],
					'3': ['110','220','440'],
					'4': ['110','110','110','440'],
					'5': []
				},
	  	},
			hingeOptions: [
				{text: 'Hinge Left', value: 'left'},
				{text: 'Hinge Right', value: 'right'}
			]
		}
	},
	methods: {
		validCabinet() {
			if (this.content.type === 'tall') {
				if (this.content.width >= 375 && this.content.width <= 500) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},
		drawerTitle(location) {
			if (this.content.specifications === '1') {
				if (location === 1) {
					return 'Drawer (110 mm)'
				} else {
					return 'Door (Hinge ' + this.content.hinge + ')'
				}
			} else {
				return 'Drawer ' + location + ' (' + this.drawerSizes[this.content.type][this.content.specifications][location-1] + ' mm high)'
			}
		},
		onDrawerUpdate() {
		},
		handleInput(e) {
			this.$emit('input', this.content)
		}
	},
	computed: {
		description() {
			var d = {}
			d['Width'] = this.content.width + ' mm'
			if (this.content.specifications === '1') {
				d['Specifications'] = 'Drawer <small>(110)</small> and Door'
			} else {
				d['Specifications'] = this.cabinetSpecificationText[this.content.type][this.content.specifications]
			}
			for (var i = 0; i < this.drawerSizes[this.content.type].length; i++) {
			}
			this.$emit('change', d)
			return d
		}
	}
}
</script>

<style lang="scss">
</style>
