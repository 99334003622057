<template>
    <b-card no-body class="mb-1">
      <b-card-header v-b-toggle="this.collapseId" header-tag="header" header-bg-variant="secondary" header-text-variant="white" class="p-1 pl-2" role="tab" variant="info">
      	<font-awesome-icon icon="caret-down" fixed-width />
       	{{ title }}
      </b-card-header>
      <b-collapse :id="collapseId" visible role="tabpanel">
        <b-card-body>
        	<b-form-radio-group v-if="size==='Door'" v-model="content.option" stacked>
        		<b-form-radio value="open">Open/No Shelf</b-form-radio>
        		<b-form-radio value="shelf">Adjustable Shelf</b-form-radio>
        		<b-form-radio value="cpu">CPU Storage</b-form-radio>
        	</b-form-radio-group>
        	<b-form-radio-group v-else v-model="content.option" stacked>
						<b-form-radio value="standard">Standard Drawer</b-form-radio>
						<b-form-radio value="removable">Removable Drawertop Insert</b-form-radio>
						<b-form-radio value="keyboard" v-if="this.cabinet.width >= 500 && this.location === 1">Keyboard Tray</b-form-radio>
						<b-form-radio value="perfect_pull" v-if="this.cabinet.width === 500 && this.location === 1">Perfect Pull</b-form-radio>
						<b-form-select v-if="content.option === 'perfect_pull'" v-model="content.perfectPull" :options=perfectPullOptions size="sm" />
						<b-form-radio value="tray" v-if="this.cabinet.width === 500">Medical Tray Drawer Insert</b-form-radio>
						<b-form-select v-if="content.option === 'tray'" v-model="content.tray" :options=medicalTrayOptions size="sm" />
						<b-form-radio value="rollout" v-if="this.cabinet.width >= 375 && this.location === 1">Rollout Equipment Pocket</b-form-radio>
						<b-form-text v-if="content.option === 'rollout'" text-variant="warning">Requires 330 mm height, may void drawer(s) below.</b-form-text>
						<b-form-radio value="cpu" v-if="this.bottom && this.cabinet.type!=='tall' && this.cabinet.width > 375">CPU Storage</b-form-radio>
						<b-form-radio value="cpu" v-if="this.bottom && this.cabinet.type==='tall' && this.cabinet.width > 250">CPU Storage</b-form-radio>
					</b-form-radio-group>
        </b-card-body>
      </b-collapse>
    </b-card>
</template>

<script>
export default {
  props: {
  	value: Object,
  	title: String,
  	cabinet: Object,
  	size: String,
  	location: Number,
  	bottom: Boolean
  },
	data() {
		return {
			content: this.value,
			collapseId: 'Drawer_' + this.location,
	  	perfectPullOptions: [
	  		{text: 'No Delivery', value: 'no_delivery'},
	  		{text: 'With Delivery', value: 'with_delivery'}
	  	],
	  	medicalTrayOptions: [
	  		{text: '4 Compartment', value: 4},
	  		{text: '5 Compartment', value: 5},
	  		{text: '8 Compartment', value: 8},
	  		{text: '9 Compartment', value: 9},
	  		{text: '16 Compartment', value: 16}
	  	]
		}
	},
	methods: {
		handleInput(e) {
			this.$emit('input', this.content)
		}
	}
}
</script>

<style lang="scss">
	.card-body .custom-select {
		margin-left: 15px;
		width: 90%;
	}
</style>
